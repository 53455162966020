<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="资金方编号">
          <el-input
            @keyup.native = "searchForm.unitNo=searchForm.unitNo.replace(/[^\d]/g,'')"
            v-model="searchForm.unitNo"
            clearable
            placeholder="请输入资金方编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="资金方名称">
          <el-input
            v-model="searchForm.unitName"
            clearable
            placeholder="请输入资金方名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联账号">
          <el-input
            v-model="searchForm.relatePhone"
            clearable
            placeholder="请输入关联账号"
            :maxlength="11"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="unitNo" width="200" label="资金方编号"/>
        <el-table-column prop="name" width="150" label="资金方名称"/>
        <el-table-column prop="identifier" label="资金方标识" width="130"/>
        <el-table-column prop="role" label="角色" width="130">
          <template slot-scope="{ row }">
            {{ $changeObj(ROLE_OPTIONS)[row.role] }}
          </template>
        </el-table-column>
        <el-table-column prop="relatePhone" label="关联账号"/>
        <el-table-column prop="createTime" label="创建时间" min-width="150"/>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryHaiLiUnit, newDelCashPerson } from "@/api/fundingParty";
import { ROLE_OPTIONS } from "@/js/constant";

export default {
  data() {
    return {
      ROLE_OPTIONS,
      searchForm: {
        unitNo: "",
        relatePhone: "",
      },
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.queryHaiLiUnit();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addFundingParty' || from.path === '/leaseholdMgmt/viewContract') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
        this.queryHaiLiUnit();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.queryHaiLiUnit();
    },
    async queryHaiLiUnit() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        pageSize: this.pageSize,
        beginIndex: page,
        role: 0
      };
      console.log(params,'params111');

      const res = await queryHaiLiUnit(params);
      this.list = res.ldata;
      this.total = res.mdata.total || 1;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryHaiLiUnit();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将删除资金方, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            unitMemberNo: row.unitMemberNo,
            role: 0
          };
          await newDelCashPerson(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.queryHaiLiUnit();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrUpdateUnit' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addFundingParty");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addFundingParty");
      })
      
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrUpdateUnit' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addFundingParty",
            query: {
              unitNo: row.unitNo,
              type: "edit"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addFundingParty",
          query: {
            unitNo: row.unitNo,
            type: "edit"
          }
        });
      })
      
    }
  },
  components: {}
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
