<template>
  <el-form
    label-position="top"
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="150px"
  >
    <el-form-item label="资金方名称" prop="name">
      <el-input
        v-model="form.name"
        clearable
        placeholder="请输入资金方名称"
        style="width: 300px"
      ></el-input>
    </el-form-item>
    <el-form-item label="关联账号" prop="relatePhone">
      <el-input
        v-model="form.relatePhone"
        clearable
        placeholder="请输入关联账号"
        style="width: 300px"
        :maxlength="11"
      ></el-input>
    </el-form-item>
    <el-form-item label="支付宝账号类型" prop="accountType">
      <el-select
        v-model="form.accountType"
        clearable
        placeholder="请选择支付宝账户类型"
        style="width: 300px"
        @change="handleChangeType"
      >
        <el-option
          v-for="opt in ALIPAY_STATUS"
          :key="opt.value"
          :value="opt.value"
          :label="opt.label"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="资金账号" prop="accountNo">
      <el-input
        v-model="form.accountNo"
        style="width: 300px"
        clearable
        placeholder="请输入资金账号"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="公司名称" prop="accountName">
      <el-input
        v-model="form.accountName"
        style="width: 300px"
        clearable
        placeholder="请输入公司名称"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="还款日期" prop="settlementPeriod">
      <el-input-number
        v-model="form.settlementPeriod"
        :min="1"
        :max="28"
        label="请选择还款日期"
        style="width: 300px"
      ></el-input-number>
    </el-form-item>
    <el-form-item label="资金方标识(外部订单号前缀以区分资金方)：" prop="identifier">
      <el-input 
      style="width: 300px" 
      placeholder="请输入资金方标识（四位字母或数字）" 
      @keyup.native="keyupHandle"
      v-model="form.identifier"
      maxlength="4"
      ></el-input>
    </el-form-item>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { createOrUpdateUnit, queryUnitChannlAccount } from "@/api/fundingParty";
import { queryHaiLiUnitDetail } from "@/api/common";

import { ALIPAY_STATUS } from "@/js/constant";
import Reg from "@/js/reg";
export default {
  data() {
    const validPhone = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isPoneAvailable(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };

    return {
      
      form: {
        name: "",
        relatePhone: "",
        accountNo: "",
        accountType: "",
        accountName: "",
        role: 0,
        settlementPeriod: "",
        identifier:""
      },
      ALIPAY_STATUS,
      rules: {
        name: [
          { required: true, message: "请输入资金方名称", trigger: "blur" }
        ],
        relatePhone: [
          { required: true, message: "请输入关联账号", trigger: "blur" },
          { validator: validPhone, trigger: "blur" }
        ]
      },
      disaled: false
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      this.queryHaiLiUnitDetail();
    }
  },
  methods: {
    //input数字字母
    keyupHandle(){
      this.form.identifier = this.form.identifier.replace(/[^\w\.\/]/ig,'')
    },
    /** 获取账户信息 */
    async queryHaiLiUnitDetail() {
      const params = {
        unitNo: this.$route.query.unitNo
      };
      const res = await queryHaiLiUnitDetail(params);
      this.form = res.mdata.detail;
    },
    handleChangeType(value) {
      this.form.accountType = value;
      if (this.$route.query.type == "edit") {
        this.queryUnitChannlAccount();
      }
    },
    //根据支付宝账户类型获取信息
    async queryUnitChannlAccount() {
      const params = {
        unitNo: this.$route.query.unitNo,
        accountType: this.form.accountType
      };
      const res = await queryUnitChannlAccount(params);
      this.$set(this.form, "accountName", res.mdata.accountName);
      this.$set(this.form, "accountNo", res.mdata.accountNo);
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disaled = true;
          const params = { ...this.form };
          if (this.$route.query.type == "edit") {
            params.unitNo = this.$route.query.unitNo;
          }
          try {
            const res = await createOrUpdateUnit(params);
            this.disaled = false;
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/fundingParty");
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/fundingParty');

    }
  }
};
</script>

<style scoped></style>
